document.addEventListener("DOMContentLoaded", (event) => {
  const data = JSON.parse(localStorage.genieLanding || '{}');

  const rerenderLanguage = () => {
    [...document.querySelectorAll('[data-t]')].map((item) => item.innerHTML = i18next.t(item.dataset.t));
    document.querySelectorAll('.langs').forEach(({ children }) => [...children].forEach((item) => item?.classList?.remove('active')))
    document.querySelectorAll('.langs').forEach((item) => item.querySelector(`.${data.lang}`)?.classList?.add('active'))
  }

  // handle change languge
  document.querySelectorAll('.langs').forEach((item) => item.addEventListener('click', ({ target }) => {
    data.lang = target.textContent;
    localStorage.genieLanding = JSON.stringify(data);
    i18next.changeLanguage(data.lang);
    rerenderLanguage();
  }));

  document.querySelector('.menuButton').addEventListener('click', ({ currentTarget }) => {
    currentTarget.classList.toggle('active');
    document.querySelector('.menuMobile').classList.toggle('active');
  });

  document.querySelectorAll('.menuMobile > a, .menuMobile .en, .menuMobile .ru').forEach((item) => {
    item.addEventListener('click', ({ target }) => {
      document.querySelector('.menuMobile').classList.toggle('active');
      document.querySelector('.menuButton').classList.toggle('active');
    })
  });

  if (location.pathname.includes('privacy-policy')) {
    document.querySelectorAll('.langs').forEach((item) => item.classList.add('hidden'))
  }

  window.addEventListener('load', rerenderLanguage);
});